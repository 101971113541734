import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export const LAYOUT_VARIANTS = [
  'carbon',
  'moss',
  'blurple',
  'polar',
] as const

export type LayoutVariant = typeof LAYOUT_VARIANTS[number]

export const LAYOUT_SOCIAL_ICONS = [
  {
    label: 'Lenke til Facebook side',
    url: FACEBOOK_URL,
    icon: faFacebookF,
  },
  {
    label: 'Lenke til Instagram side',
    url: INSTAGRAM_URL,
    icon: faInstagram,
  },
  {
    label: 'Lenke til LinkedIn side',
    url: LINKEDIN_URL,
    icon: faLinkedinIn,
  },
] as const

export const LAYOUT_VISIT = [
  'Postboks 508 Sentrum',
  '0105 Oslo',
] as const

export const LAYOUT_CONTACT_URLS = [
  {
    label: 'post@juridika.no',
    url: 'mailto:post@juridika.no',
  },
  {
    label: '24 14 75 00',
    url: 'tel:+4724147500',
  },
] as const

export const LAYOUT_FOOTER_LINKS = [
  {
    label: 'Brukervilkår',
    url: '/brukeravtale',
  },
  {
    label: 'Personvern',
    url: '/personvern',
  },
  {
    label: 'Hjelpesider',
    url: '/bruk-av-informasjonskapsler',
  },
] as const
