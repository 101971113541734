<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="154" height="22" viewBox="0 0 154 22" fill="none">
    <g clip-path="url(#clip0_2250_2756)">
      <path d="M28.9246 4.84552V13.3023C28.9246 14.8491 29.7383 15.7976 31.2177 15.7976C32.6971 15.7976 33.5108 14.8548 33.5108 13.3023V4.84552H34.9903V13.4039C34.9903 15.5943 33.6474 17.0903 31.2234 17.0903C28.7994 17.0903 27.4395 15.5886 27.4395 13.4039V4.84552H28.9246Z" />
      <path d="M36.9819 8.38511H38.2906V9.94888C38.7345 8.75208 39.4969 8.16492 40.652 8.16492C42.1485 8.16492 42.8939 9.10771 42.8939 11.0441V16.8024H41.5169V11.1626C41.5169 9.93195 41.056 9.34484 40.1058 9.34484C38.9848 9.34484 38.376 10.2876 38.376 12.1562V16.8024H36.9819V8.38511Z" />
      <path d="M44.8684 5.06561H46.4161V6.88343H44.8684V5.06561ZM44.9537 8.38508H46.3478V16.808H44.9537V8.38508Z" />
      <path d="M47.85 8.38514H49.3125L50.809 14.0588C50.9285 14.516 51.0137 14.9338 51.0821 15.3403H51.1332C51.2188 14.9338 51.304 14.516 51.4235 14.0588L52.9028 8.38514H54.3653L51.8501 16.808H50.3709L47.85 8.38514Z" />
      <path d="M61.512 12.9183H56.5902C56.6414 14.8547 57.1991 15.7975 58.5248 15.7975C59.4239 15.7975 60.0667 15.3233 60.2547 14.2337L61.4953 14.6063C61.205 16.187 60.0839 17.0169 58.4908 17.0169C56.6075 17.0169 55.1961 15.8709 55.1961 12.6022C55.1961 9.33352 56.6586 8.18751 58.4564 8.18751C60.391 8.18751 61.512 9.48594 61.512 11.8401V12.9183ZM60.1069 11.8739C60.1069 10.0392 59.532 9.2658 58.4621 9.2658C57.4607 9.2658 56.6471 9.77386 56.596 11.8739H60.1069Z" />
      <path d="M66.9861 8.19885C67.0712 8.19885 67.1396 8.19885 67.2247 8.21578V9.6497H67.0372C66.2744 9.6497 65.8138 9.95455 65.4781 10.4062C64.9032 11.078 64.886 12.0377 64.886 12.9353V16.808H63.4924V8.38513H64.7837V10.3046C65.0855 9.17551 65.7794 8.19885 66.9861 8.19885Z" />
      <path d="M68.2377 14.1265L69.6146 13.912C69.7169 15.1596 70.3774 15.8992 71.3445 15.8992C72.2608 15.8992 72.7898 15.4419 72.7898 14.6007C72.7898 13.8273 72.4655 13.4039 71.5664 13.2176L70.2918 12.9636C69.0512 12.7095 68.3572 11.8176 68.3572 10.6038C68.3572 9.10213 69.5634 8.17631 71.1909 8.17631C72.9036 8.17631 74.0586 9.06827 74.1098 10.8691L72.7673 11.0893C72.6817 9.8078 72.0901 9.25455 71.1909 9.25455C70.309 9.25455 69.7456 9.72875 69.7456 10.5361C69.7456 11.2248 70.0527 11.6313 70.9178 11.8176L72.2436 12.1055C73.6549 12.4103 74.1954 13.2515 74.1954 14.516C74.1954 15.9669 73.1427 17.0113 71.3272 17.0113C69.5295 17.0113 68.3745 16.046 68.2377 14.1265Z" />
      <path d="M76.0161 5.06561H77.5638V6.88343H76.0161V5.06561ZM76.1012 8.38508H77.4954V16.808H76.1012V8.38508Z" />
      <path d="M78.8498 8.3851H80.1583V6.26244L81.5357 5.73745V8.37946H83.2995V9.47468H81.5357V14.3748C81.5357 15.2499 81.8427 15.6225 82.5884 15.6225C82.9126 15.6225 83.1001 15.5886 83.3335 15.5378V16.8024C83.0092 16.8871 82.6907 16.9378 82.2297 16.9378C80.6705 16.9378 80.1583 16.0459 80.1583 14.5781V9.47468H78.8498V8.3851Z" />
      <path d="M90.7705 12.9183H85.8487C85.8999 14.8547 86.4575 15.7975 87.7833 15.7975C88.6824 15.7975 89.3252 15.3233 89.5132 14.2337L90.7538 14.6063C90.4635 16.187 89.3424 17.0169 87.7493 17.0169C85.8659 17.0169 84.4546 15.8709 84.4546 12.6022C84.4546 9.33352 85.9171 8.18751 87.7149 8.18751C89.6499 8.18751 90.7705 9.48594 90.7705 11.8401V12.9183ZM89.3596 11.8739C89.3596 10.0392 88.7848 9.2658 87.7149 9.2658C86.7134 9.2658 85.8999 9.77386 85.8487 11.8739H89.3596Z" />
      <path d="M91.6755 8.3851H92.984V6.26244L94.3614 5.73745V8.37946H96.1252V9.47468H94.3614V14.3748C94.3614 15.2499 94.6684 15.6225 95.414 15.6225C95.7383 15.6225 95.9205 15.5886 96.1592 15.5378V16.8024C95.8349 16.8871 95.5164 16.9378 95.0553 16.9378C93.4962 16.9378 92.984 16.0459 92.984 14.5781V9.47468H91.6755V8.3851Z" />
      <path d="M97.3997 14.1265L98.7766 13.9063C98.8789 15.1539 99.5389 15.8935 100.506 15.8935C101.423 15.8935 101.952 15.4362 101.952 14.5951C101.952 13.8216 101.627 13.3982 100.728 13.2119L99.4538 12.9579C98.2132 12.7038 97.5192 11.8119 97.5192 10.5982C97.5192 9.09646 98.7254 8.17064 100.353 8.17064C102.066 8.17064 103.221 9.0626 103.272 10.8634L101.929 11.0836C101.844 9.80214 101.252 9.24888 100.353 9.24888C99.471 9.24888 98.9076 9.72308 98.9076 10.5304C98.9076 11.2191 99.2147 11.6256 100.08 11.8119L101.406 12.0998C102.816 12.4046 103.357 13.2458 103.357 14.5104C103.357 15.9612 102.305 17.0056 100.489 17.0056C98.6857 17.0112 97.5364 16.0459 97.3997 14.1265Z" />
      <path d="M108.609 9.48028H106.845V16.808H105.468V9.48028H104.177V8.38511H105.468V6.86649C105.468 5.41564 106.316 4.48981 107.693 4.48981C108.052 4.48981 108.353 4.52368 108.609 4.59142V5.75435C108.37 5.72049 108.148 5.70355 107.932 5.70355C107.17 5.70355 106.845 6.0931 106.845 7.00198V8.38511H108.609V9.48028Z" />
      <path d="M109.286 12.5966C109.286 9.56499 110.561 8.1819 112.615 8.1819C114.669 8.1819 115.944 9.56499 115.944 12.5966C115.944 15.6281 114.669 17.0113 112.615 17.0113C110.561 17.0113 109.286 15.6281 109.286 12.5966ZM114.465 12.5966C114.465 10.0674 113.685 9.27707 112.615 9.27707C111.545 9.27707 110.766 10.0674 110.766 12.5966C110.766 15.1257 111.545 15.9161 112.615 15.9161C113.685 15.9161 114.465 15.12 114.465 12.5966Z" />
      <path d="M121.19 8.19885C121.275 8.19885 121.344 8.19885 121.429 8.21578V9.6497H121.241C120.479 9.6497 120.018 9.95455 119.682 10.4062C119.107 11.078 119.09 12.0377 119.09 12.9353V16.808H117.696V8.38513H118.988V10.3046C119.29 9.17551 119.989 8.19885 121.19 8.19885Z" />
      <path d="M124.24 16.808H122.846V4.59705H124.24V16.808Z" />
      <path d="M125.958 14.6176C125.958 13.6918 126.351 13.0538 126.909 12.6304C127.398 12.2578 128.064 12.0264 129.134 11.8401L130.578 11.586V11.0666C130.578 9.81905 130.152 9.28273 129.168 9.28273C127.978 9.28273 127.62 10.0053 127.591 11.0666H126.163C126.197 8.82547 127.66 8.18751 129.168 8.18751C131.153 8.18751 131.967 9.09645 131.967 11.1852V14.3184C131.967 15.3797 132.035 16.1701 132.172 16.8137H130.795C130.727 16.3225 130.676 15.7862 130.676 15.312C130.385 16.3394 129.521 17.0112 128.331 17.0112C126.806 17.0112 125.958 16.0459 125.958 14.6176ZM130.596 13.7256V12.5796L129.099 12.8675C128.029 13.0707 127.455 13.5563 127.438 14.4821C127.42 15.408 127.882 15.8991 128.781 15.8991C129.862 15.8991 130.596 15.0692 130.596 13.7256Z" />
      <path d="M138.75 8.79151C139.358 9.29962 139.666 10.073 139.666 11.0497C139.666 12.8167 138.698 13.9288 136.815 13.9288C136.326 13.9288 135.882 13.8441 135.507 13.6917C135.216 13.8272 135.012 14.0812 135.012 14.4143C135.012 14.8716 135.353 15.1708 136.133 15.2385L138.033 15.4079C139.495 15.5434 140.446 16.3676 140.446 17.6999C140.446 19.3314 139.222 20.2912 136.866 20.2912C134.59 20.2912 133.504 19.3822 133.504 18.0499C133.504 17.2426 133.993 16.5821 134.898 16.3168C134.203 16.0458 133.862 15.5265 133.862 14.8998C133.862 14.1772 134.34 13.6861 135.034 13.432C134.323 12.9409 133.947 12.1167 133.947 11.0553C133.947 9.32219 134.949 8.17618 136.815 8.17618C137.356 8.17618 137.834 8.27781 138.226 8.4641C138.482 7.58907 139.108 7.01324 140.076 7.01324C140.212 7.01324 140.332 7.03018 140.468 7.06404V8.21004C140.281 8.15925 140.127 8.14232 139.928 8.14232C139.381 8.14797 138.972 8.38509 138.75 8.79151ZM135.797 16.5031C135.711 16.5031 135.626 16.4862 135.558 16.4692C135.051 16.7233 134.795 17.158 134.795 17.7507C134.795 18.7951 135.626 19.3145 136.952 19.3145C138.482 19.3145 139.091 18.7782 139.091 17.8636C139.091 17.0733 138.619 16.7007 137.68 16.6329L135.797 16.5031ZM138.312 11.044C138.312 9.76253 137.737 9.12462 136.815 9.12462C135.882 9.12462 135.302 9.77946 135.302 11.061C135.302 12.3425 135.893 13.0143 136.815 13.0143C137.731 13.0143 138.312 12.3425 138.312 11.044Z" />
      <path d="M147.655 12.9183H142.733C142.784 14.8547 143.342 15.7975 144.668 15.7975C145.567 15.7975 146.209 15.3233 146.397 14.2337L147.638 14.6063C147.348 16.187 146.227 17.0169 144.633 17.0169C142.75 17.0169 141.339 15.8709 141.339 12.6022C141.339 9.33352 142.801 8.18751 144.599 8.18751C146.534 8.18751 147.655 9.48594 147.655 11.8401V12.9183ZM146.243 11.8739C146.243 10.0392 145.669 9.2658 144.599 9.2658C143.598 9.2658 142.784 9.77386 142.733 11.8739H146.243Z" />
      <path d="M148.56 8.3851H149.868V6.26244L151.245 5.73745V8.37946H153.009V9.47468H151.245V14.3748C151.245 15.2499 151.553 15.6225 152.298 15.6225C152.623 15.6225 152.81 15.5886 153.043 15.5378V16.8024C152.719 16.8871 152.401 16.9378 151.94 16.9378C150.38 16.9378 149.868 16.0459 149.868 14.5781V9.47468H148.56V8.3851Z" />
      <path d="M0 18.3136C0 20.4983 1.39525 22 3.89263 22H10.1478C12.6511 22 14.0287 20.4983 14.0287 18.3136V0H12.5045V18.2176C12.5045 19.7701 11.6662 20.7129 10.142 20.7129C8.61774 20.7129 7.77939 19.7701 7.77939 18.2176V0H0V18.3136ZM1.54181 18.2176V1.29844H6.24934V18.3192C6.24934 19.2676 6.51315 20.0862 7.01728 20.7129H3.8985C2.38013 20.7072 1.54181 19.7644 1.54181 18.2176Z" />
    </g>
    <defs>
      <clipPath id="clip0_2250_2756">
        <rect width="153.043" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
