<script setup lang='ts'>
import { KsInput } from '@aschehoug/kloss'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

withDefaults(defineProps<{
  variant?: LayoutVariant
}>(), {
  variant: 'blurple',
})

defineEmits<{
  (event: 'update:modelValue', value: any): void
}>()

const email = defineModel({ default: '' })
</script>

<template>
  <div
    class="border rounded-lg relative flex items-center w-full dark:border-carbon-200"
    :class="{
      'border-primary-500': variant === 'carbon',
      'border-polar-500': variant === 'polar',
      'border-moss-500': variant === 'moss',
    }"
  >
    <KsInput
      v-model="email"
      type="text"
      placeholder="Din e-postadresse"
      :class="{
        'dark:text-carbon-200 placeholder:text-blurple-500': variant === 'carbon',
        'text-blurple-500 placeholder:text-blurple-500': variant === 'blurple',
        'text-polar-500 placeholder:text-polar-500': variant === 'polar',
        'text-moss-500': variant === 'moss',
      }"
    />
    <Button variant="tertiary" size="medium" class="!p-0 absolute right-4 top-3 hover:!bg-transparent" type="submit" aria-label="Send inn">
      <Shape
        variant="hexagon"
        :icon="faArrowRight"
        class="w-9"
        :class="{
          'fill-blurple-500 dark:fill-carbon-200 text-carbon-200 dark:text-carbon-500': variant === 'carbon',
          'fill-blurple-400 dark:fill-blurple-300 text-white dark:text-blurple-500': variant === 'blurple',
          'dark:fill-polar-200 text-polar-200 dark:text-polar-500': variant === 'polar',
          'fill-moss-300 text-moss-200 dark:fill-moss-200 dark:text-moss-500': variant === 'moss',
        }"
      />
    </Button>
  </div>
</template>

<style scoped lang='postcss'>
div {
  input[type='text'] {
    @apply bg-transparent rounded-md dark:text-carbon-200 dark:placeholder:text-carbon-200 px-6 py-5 h-14 !important;
  }
}
</style>
