<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="98" height="22" viewBox="0 0 98 22" fill="none">
    <path d="M54.56 15.5651C54.505 16.7751 54.065 17.5726 52.9925 17.5726H50.7375V11.7701H50.9025C52.0025 11.8251 52.36 11.9901 52.36 13.2001V13.4751L53.13 13.3376V9.5701L52.36 9.6526V9.8176C52.36 11.0276 52.0025 11.1926 50.9025 11.2476H50.7375V6.0226H52.69C53.7625 6.0226 54.175 6.8201 54.23 8.0301V8.3051L55.1925 8.2501L55 4.6201L54.0375 4.6751L54.065 5.3076H48.18L48.125 5.9401C48.125 5.9401 48.29 5.9676 48.455 5.9951C48.7575 6.0776 48.9225 6.2426 48.9225 6.5451V17.0776C48.9225 17.3801 48.7575 17.5451 48.455 17.6276C48.29 17.6551 48.2075 17.6826 48.2075 17.6826L48.1525 18.3151H50.0775H54.45L54.4225 18.9476L55.385 19.0026L55.5775 15.3726L54.615 15.3176L54.56 15.5651Z" />
    <path d="M20.5424 17.0775L17.6549 5.94004C16.5824 1.73254 13.8324 0.330038 10.9174 0.220037C7.91993 0.0825368 5.99493 2.06254 5.93993 3.57504C5.91243 4.73004 6.62743 5.33504 7.61743 5.33504L8.22243 4.73004C5.85743 4.73004 6.70993 0.742538 10.4224 0.742538C14.8499 0.742538 15.5099 5.14254 15.5099 5.14254L13.0624 12.3475H12.1549C11.0824 12.2925 10.9174 12.0175 11.3024 10.8625L11.3849 10.615L10.6699 10.5325L9.43244 14.3L10.1199 14.3825L10.2024 14.1625C10.5599 13.035 11.0274 13.145 12.0724 13.09H12.8149L12.3199 14.52C11.2199 17.6 8.57994 20.845 5.33493 20.8725C2.63993 20.9 1.45743 19.1125 1.56743 17.3525C1.59493 17.05 1.62243 16.8025 1.70493 16.555C2.28243 16.6925 2.96993 16.6925 3.79493 16.4725C5.99493 15.895 6.29743 14.63 6.04993 14.1075C5.82993 13.64 5.14243 13.3375 3.98743 13.6125C3.02493 13.86 2.06243 14.41 1.34743 15.2075C-0.13757 12.925 2.61243 11.1925 2.61243 11.1925L2.28243 11.0275C0.962431 11.6325 -0.13757 13.0625 0.0274299 14.63C0.0824298 15.235 0.329931 15.73 0.742431 16.0875C0.549931 16.4725 0.412431 16.885 0.329931 17.3525C-0.0825699 20.3225 2.96993 21.6975 5.63743 21.4775C9.89993 21.12 12.4849 17.985 13.5024 15.07L14.1899 13.0625H17.6549L18.6999 17.05C18.7824 17.3525 18.5349 17.5175 18.2324 17.6C18.0674 17.6275 17.7099 17.655 17.7099 17.655L17.6549 18.2875H21.5599L21.6149 17.655C21.6149 17.655 21.1474 17.6275 20.9824 17.6C20.7899 17.5725 20.6249 17.38 20.5424 17.0775ZM3.40993 14.4375C4.01493 14.1625 4.83993 14.08 4.97743 14.6575C5.11493 15.2625 4.50993 15.62 4.06993 15.785C3.79493 15.895 2.69493 16.17 1.95243 15.84C2.39243 15.0425 3.05243 14.6025 3.40993 14.4375ZM14.4649 12.32L16.1699 7.42504L17.4624 12.3475L14.4649 12.32Z" />
    <path d="M46.2 17.0775V6.51746C46.2 6.21496 46.3925 6.04996 46.6675 5.99496C46.8325 5.96746 46.915 5.93996 46.915 5.93996L46.97 5.30746H43.6425L43.5875 5.93996C43.5875 5.93996 43.7525 5.96746 43.9175 5.99496C44.22 6.07746 44.4125 6.26996 44.4125 6.57246V11.165H41.1125V6.51746C41.1125 6.21496 41.2775 6.04996 41.58 5.96746C41.745 5.93996 41.8275 5.91246 41.8275 5.91246L41.8825 5.27996H38.555L38.5 5.91246C38.5 5.91246 38.665 5.93996 38.83 5.96746C39.1325 6.04996 39.2975 6.21496 39.2975 6.51746V17.0775C39.2975 17.38 39.1325 17.545 38.83 17.6275C38.665 17.655 38.5825 17.6825 38.5825 17.6825L38.5275 18.315H41.855L41.91 17.6825C41.91 17.6825 41.745 17.655 41.58 17.6275C41.2775 17.545 41.1125 17.38 41.1125 17.0775V11.88H44.4125V17.05C44.4125 17.3525 44.22 17.545 43.9175 17.6275C43.7525 17.655 43.67 17.6825 43.67 17.6825L43.615 18.315H46.9425L46.9975 17.6825C46.9975 17.6825 46.8325 17.655 46.6675 17.6275C46.365 17.545 46.2 17.38 46.2 17.0775Z" />
    <path d="M64.3226 17.0775V6.51746C64.3226 6.21496 64.5151 6.04996 64.7901 5.99496C64.9551 5.96746 65.0376 5.93996 65.0376 5.93996L65.0926 5.30746H61.7651L61.7101 5.93996C61.7101 5.93996 61.8751 5.96746 62.0401 5.99496C62.3426 6.07746 62.5351 6.26996 62.5351 6.57246V11.165H59.2351V6.51746C59.2351 6.21496 59.4001 6.04996 59.7026 5.96746C59.8676 5.93996 59.9501 5.91246 59.9501 5.91246L60.0051 5.27996H56.6501L56.5951 5.91246C56.5951 5.91246 56.7601 5.93996 56.9251 5.96746C57.2276 6.04996 57.3926 6.21496 57.3926 6.51746V17.0775C57.3926 17.38 57.2276 17.545 56.9251 17.6275C56.7601 17.655 56.6776 17.6825 56.6776 17.6825L56.6226 18.315H59.9501L60.0051 17.6825C60.0051 17.6825 59.8401 17.655 59.6751 17.6275C59.3726 17.545 59.2076 17.38 59.2076 17.0775V11.88H62.5076V17.05C62.5076 17.3525 62.3151 17.545 62.0126 17.6275C61.8476 17.655 61.7651 17.6825 61.7651 17.6825L61.7101 18.315H65.0376L65.0926 17.6825C65.0926 17.6825 64.9276 17.655 64.7626 17.6275C64.4876 17.545 64.3226 17.38 64.3226 17.0775Z" />
    <path d="M69.7949 5.08752C67.6224 5.08752 66.3299 6.43502 66.3299 8.99252V14.5475C66.3299 17.0775 67.6499 18.4525 69.7949 18.4525C71.9674 18.4525 73.2599 17.105 73.2599 14.5475V8.99252C73.2874 6.46252 71.9674 5.08752 69.7949 5.08752ZM71.4724 14.63C71.4724 17.0225 70.9224 17.82 69.7949 17.82C68.6674 17.82 68.1449 17.0225 68.1449 14.63V8.91002C68.1449 6.51752 68.6949 5.72002 69.8224 5.72002C70.9499 5.72002 71.4724 6.51752 71.4724 8.91002V14.63Z" />
    <path d="M35.6674 14.6301C35.6674 17.0226 35.1174 17.8201 33.9899 17.8201C32.8624 17.8201 32.3399 17.0226 32.3399 14.6301V8.91008C32.3399 6.51758 32.8899 5.72008 34.0174 5.72008C35.0349 5.72008 35.5849 6.35258 35.6674 8.27758L37.4549 8.38758V4.64758H36.4924V5.50008C36.4924 5.74758 36.3274 5.85758 36.1349 5.72008C35.5849 5.33508 34.8424 5.08758 33.9899 5.08758C31.8174 5.08758 30.5249 6.43508 30.5249 8.99258V14.5476C30.5249 17.0776 31.8449 18.4526 33.9899 18.4526C36.1624 18.4526 37.4549 17.1051 37.4549 14.5476V14.1626L35.6399 14.3276V14.6301H35.6674Z" />
    <path d="M87.725 18.4526C88.66 18.4526 89.4575 18.2051 90.09 17.7101C90.2825 17.5726 90.42 17.6276 90.42 17.9026V18.8926H91.3825V13.0076H89.5675H88.165V13.8601H89.5675V14.6301C89.5675 17.0226 88.8525 17.8201 87.7525 17.8201C86.625 17.8201 86.1025 17.0226 86.1025 14.6301V8.91008C86.1025 6.51758 86.6525 5.72008 87.78 5.72008C88.7975 5.72008 89.3475 6.35258 89.43 8.27758L91.2175 8.38758V4.64758H90.255V5.50008C90.255 5.74758 90.09 5.85758 89.8975 5.72008C89.3475 5.33508 88.605 5.08758 87.7525 5.08758C85.58 5.08758 84.2875 6.43508 84.2875 8.99258V14.5476C84.26 17.1051 85.5525 18.4526 87.725 18.4526Z" />
    <path d="M26.07 10.6426C24.9425 9.92758 23.6775 9.40508 23.6775 7.94758C23.6775 6.49008 24.3375 5.74758 25.465 5.74758C26.62 5.74758 27.115 6.54508 27.115 8.27758L28.93 8.41508V4.64758H27.9675V5.36258C27.9675 5.61008 27.8025 5.69258 27.61 5.58258C27.005 5.22508 26.3725 5.08758 25.4375 5.08758C23.3475 5.08758 21.9725 6.05008 21.9725 8.38758C21.9725 10.4501 23.65 11.1926 25.025 12.0451C26.7025 13.1176 27.5 14.1076 27.5 15.1801C27.5 16.7476 27.0325 17.7651 25.74 17.7651C24.31 17.7651 23.7875 16.4176 23.7875 14.3001L21.9725 14.4651C21.9725 16.9951 23.1 18.3976 25.74 18.3976C28.38 18.3976 29.2875 17.0226 29.2875 14.8226C29.2875 12.7051 27.72 11.6876 26.07 10.6426Z" />
    <path d="M94.2975 11.1375C92.7025 11.275 92.51 14.685 94.6275 15.4825L94.7925 15.0975C94.325 14.575 94.0775 13.9975 93.9675 13.255C93.8575 12.43 93.995 11.66 94.435 11.66C95.26 11.66 96.03 13.7775 95.5625 15.785C94.875 18.81 92.565 20.8725 89.1275 20.8725C85.6625 20.8725 82.4175 18.535 82.2525 13.7225V6.51746C82.2525 6.21496 82.4175 6.04996 82.72 5.96746C82.885 5.93996 82.9675 5.91246 82.9675 5.91246L83.0225 5.27996H79.695L79.64 5.91246C79.64 5.91246 79.805 5.93996 79.97 5.96746C80.2725 6.04996 80.4375 6.21496 80.4375 6.51746V14.63C80.4375 17.0225 79.8875 17.82 78.76 17.82C77.6325 17.82 77.11 17.0225 77.11 14.63V6.54496C77.11 6.24246 77.275 6.04996 77.5775 5.99496C77.7425 5.96746 77.825 5.93996 77.825 5.93996L77.88 5.30746H74.5525L74.4975 5.93996C74.4975 5.93996 74.6625 5.96746 74.8275 5.99496C75.13 6.07746 75.295 6.24246 75.295 6.54496V14.575C75.295 17.105 76.615 18.48 78.76 18.48C80.0525 18.48 81.0425 17.985 81.6475 17.05C81.73 16.9125 81.8125 16.9125 81.8675 17.0225C83.435 20.68 86.4325 21.5325 89.2925 21.5325C93.0875 21.5325 96.25 19.085 96.745 15.7575C97.185 13.0075 95.6175 11 94.2975 11.1375Z" />
  </svg>
</template>
